<template functional>
  <v-row justify="center" class="mb-10 mb-16 py-5">
    <v-col class="text-center mt-16 mx-auto" align="center" col="auto" id="banner">
      <div class="banner_text text-center">{{ props.title }}</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Banner",
  props: {
    title: {
      type: String,
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap");
#banner {
  height: 200px;
  background: linear-gradient(135deg, #1e90ff 0%, #00aeef 50%, #aff5f9 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .banner_text {
    resize: vertical;
    color: white;
    font-family: "Bowlby One SC", cursive;
    font-size: 80px;
    font-size: 4vw;
    font-size-adjust: 0.5;
    letter-spacing: 0.2em;
    width: 100%;
    margin: auto;
  }
}
</style>
